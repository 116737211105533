.favorite-circle {
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(238, 238, 238);
    border-radius: 25px;
    cursor: pointer;
}

.favorite-circle:active {
    background-color: rgb(202, 202, 202);
}

.fa-heart {
    color: rgb(28, 73, 194);
}

.favorites-main {
    background-color: rgb(252, 252, 252);
    min-height: 78.5vh;
}

.favorites-container {
    margin: auto;
    width: 80vw;
}

.favorites-text {
    padding-top: 50px;
    font-family: sans-serif;
    font-size: 35px;
    font-weight: lighter;
    color: rgba(67, 67, 67, 0.872);
}

.favorite-container {
    border: 1px rgb(223, 223, 223) solid;
    font-family: sans-serif;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0 10px 0;
}

.favorite-details {
    /* min-width: 800px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 10px;
}

.favorite-start {
    display: flex;
}

.favorite-image-container {
    width: 135px;
    height: 135px;
}

.favorite-name-link {
    text-decoration: none;
    color: black;
    font-size: 15px;
    width: fit-content;
}

.favorite-name-link:hover {
    color: rgb(14, 63, 169);
    text-decoration: underline;
}

.favorite-price {
    margin: 10px 0px;
    /* color: rgb(188, 40, 72); */
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
}

.add-favorite-to-cart {
    margin-top: 10px;
    color: rgb(22, 57, 119);
    border: 0.1rem solid rgb(22, 57, 119);
    cursor: pointer;
    font-weight: lighter;
    font-size: 15px;
    padding: 11px 16px;
    border-radius: 4px;
    width: 80px;
    height: 16px;
}

.add-favorite-to-cart:hover {
    background-color: rgba(36, 71, 132, 0.142);
}

.remove-favorite-x {
    /* display: flex;
    justify-self: end; */
    /* position: relative; */
    /* position: absolute; */
    /* left: 270px; */
    /* bottom: 22px; */
    color: rgba(0, 0, 0, 0.446);
    font-size: 24px;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
}

.no-favorites {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: sans-serif;
    /* margin-top: 44px; */
}

.no-favorites-image-container {
    margin-top: 44px;
    width: 500px;
    height: fit-content;
}
