.product-page-container {
    display: flex;
    height: 68vh;
    font-family: sans-serif;
    /* border: 2px rgba(255, 0, 0, 0.563) solid; */
    border-radius: 9px;
    margin-top: 60px;
}

.product-page-image-container {
    /* margin-top: 8px; */
    width: 350px;
    height: 400px;
    padding: 24px;
    /* object-fit: cover; */
}

.product-page-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-details-container {
    width: 35vw;
    margin: 0px 20px 0px 20px;
}

.product-page-name {
    font-weight: bold;
    font-size: 20px;
}

.product-page-price {
    font-size: 24px;
    margin-top: 40px;
}

.product-page-description {
    margin-top: 30px;
    color: rgb(90, 90, 90);
    font-weight: light;
    letter-spacing: .3px;
    word-spacing: .6px;
    line-height: 24px;
}

.add-to-cart-container {
    display: flex;
    flex-direction: column;
    border: 1px rgba(206, 206, 206, 0.531) solid;
    border-radius: 8px;
    width: 200px;
    height: 160px;
    margin-right: 25px;
}

.in-stock {
    margin: auto;
    color: rgb(10, 142, 78);
}

.add-to-cart-button {
    background-color: rgb(239, 108, 0);
    color: white;
    font-size: 14px;
    font-weight: bold;
    width: 85%;
    padding: 14px 0px 14px 0px;
    margin: 0px auto 8px auto;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;
}

.add-to-cart-button:hover {
    background-color: rgb(255, 129, 26);
}

.add-to-cart-link {
    color: white;
    text-decoration: none;
}

.autoship-link {
    text-decoration: none;
}

.autoship-button {
    /* text-decoration: none; */
    border: 2px rgb(28, 73, 194) solid;
    background-color: rgb(255, 255, 255);
    color: rgb(28, 73, 194);
    font-size: 14px;
    font-weight: bold;
    width: 85%;
    padding: 14px 0px 14px 0px;
    margin: 0px auto 14px auto;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;
}

.autoship-button:hover {
    background-color: rgba(203, 222, 255, 0.418);
}

.autoship {
    background-image: url(./images/autoship-icon.svg);
}
