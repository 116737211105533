.create-pet-main {
    height: 74vh;
}

.pet-form-container {
    margin: 20px auto 20px auto;
    padding: 16px;
    border: 1px rgba(168, 168, 168, 0.589) solid;
    border-radius: 8px;
    max-width: 686px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.top-buttons {
    display: flex;
    justify-content: space-between;
    /* border: 1px rgb(28, 73, 194) solid; */
}

.back-button {
    /* font-family: sans-serif; */
    font-size: 20px;
    /* font-weight: bolder; */
    text-align: center;
    width: 28px;
    height: 28px;
    /* margin: 20px 0px 0px 20px; */
    border-radius: 45px;
    border: 1px transparent solid;
    cursor: pointer;
    /* border: 1px rgb(28, 73, 194) solid; */
}


.back-button:hover {
    color: rgb(28, 73, 194);
    border: 1px rgb(28, 73, 194) solid;
}

.fa-chevron-left {
    margin-top: 4px;
}

.fa-xmark {
    margin-top: 2px;
}

.close-button {
    /* margin: 20px 20px 0px 0px; */
    /* font-weight: lighter; */
    font-size: 24px;
    /* border: rgb(182, 120, 44) 1px solid; */
    /* display: inline-block; */
}

.sub-text {
    font-family: sans-serif;
    font-size: 15px;
    text-align: center;
    color: rgba(123, 123, 123, 0.867);
    margin: auto auto 30px auto;
}

.pet-prompt {
    display: flex;
    /* justify-self: center; */
    font-family: sans-serif;
    font-weight: bolder;
    font-size: 24px;
    text-align: center;
    margin: auto auto 40px auto;
    /* margin: auto; */
    max-width: 50%;
}

.pet-type-images-container {
    display: flex;
}

.pet-type-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 148px;
    height: 148px;
    border-radius: 16px;
    border: 2px rgba(175, 175, 175, 0.397) solid;
    cursor: pointer;
    margin: auto;
}

.pet-image {
    /* display: block; */
    margin: 18px 0px 2px 0px;
    max-width: 64%;
    max-height: 64%;
}

.pet-type-text {
    font-family: sans-serif;
    font-weight: bolder;
}


.create-pet-form {
    /* background-color: rgb(28, 73, 194); */
    /* border: 1px rgba(186, 98, 98, 0.755) solid; */
    margin: auto;
    min-width: 435px;
    max-width: 435px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.input {
    font-size: 15px;
    padding: 16px;
    border-radius: 8px;
    border: 1px rgba(89, 89, 89, 0.755) solid;
    max-width: 400px;
    min-width: 400px;
    margin: auto;
}

.breed-input {
    font-size: 15px;
    padding: 16px;
    border-radius: 8px;
    border: 1px rgba(89, 89, 89, 0.755) solid;
    max-width: 400px;
    min-width: 400px;
    margin: auto;
}

.breed-input:focus {
    outline: none;
    border-bottom: 0px transparent solid;
    border-top: 2px rgb(28, 73, 194) solid;
    border-left: 2px rgb(28, 73, 194) solid;
    border-right: 2px rgb(28, 73, 194) solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.breed-list {
    /* position: absolute; */
    border: 2px rgb(28, 73, 194) solid;
    border-top: transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 70%;
    height: 120px;
    margin: auto;
    max-width: 400px;
    min-width: 400px;
    padding: 0px 16px 16px 16px;
    overflow: scroll;
    background-color: white;
}

/* .breed-input:focus {
    border-top: transparent;
} */

.breed-list-item {
    font-family: sans-serif;
    padding: 5px 0px 5px 0px;
    cursor: pointer;
}

.breed-list-item:hover {
    background-color: rgba(191, 191, 191, 0.304);
}

.unsure-of-breed {
    border: rgba(19, 145, 255, 0.234) 2px solid;
    background-color: transparent;
    cursor: pointer;
    width: 48%;
    padding: 4px 13px 4px 13px;
    border-radius: 45px;
    color: rgb(28, 73, 194);
    /* font-weight: bold; */
    font-size: 16px;
    /* min-width: 400px; */
    /* margin-top: 40px; */
    margin: 40px auto auto auto;
}

.unsure-of-breed:hover {
    background-color: rgba(19, 145, 255, 0.234);
    border: 2px transparent solid;
}

.pet-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: row; */
    max-width: 560px;
    margin: auto;
    /* border: 1px blue solid; */
}

.pet-icon-container {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background-color: rgb(229, 234, 240);
    cursor: pointer;
    margin: 6px;
    transition: all .23s;
}

.pet-icon-container:hover {
    /* transition-delay: .02s; */
    background-color: rgb(192, 206, 223);
}

.chosen-icon-container {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background-color: rgb(229, 234, 240);
    margin: auto;
}

.welcome-icon-container {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    width: 152px;
    height: 152px;
    border-radius: 80px;
    background-color: rgb(229, 234, 240);
    margin: auto auto 12px auto;
}

.icon-image {
    /* display: block; */
    /* margin: 1px 0px 2px 0px; */
    max-width: 100%;
    max-height: 100%;
}

.continue-button {
    border: none;
    background-color: rgb(28, 73, 194);
    cursor: pointer;
    width: 100%;
    padding: 16px;
    border-radius: 45px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    /* max-width: 400px;
    min-width: 400px; */
    margin-top: 68px;
}

.continue-button:hover {
    background-color: rgb(35, 83, 213);
}

.options-buttons {
    border: 2px rgb(28, 73, 194) solid;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    width: 100%;
    padding: 16px;
    border-radius: 45px;
    color: rgb(28, 73, 194);
    font-weight: bold;
    font-size: 15px;
    /* max-width: 400px;
    min-width: 400px; */
    margin-top: 10px;
}

.options-buttons:hover {
    background-color: rgba(203, 222, 255, 0.418);
}

.date-error {
    position: absolute;
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(208, 1, 27);
    font-family: sans-serif;
}

.date-error-edit {
    /* position: absolute; */
    margin-top: 6px;
    /* margin-bottom: 10px; */
    color: rgb(208, 1, 27);
    font-family: sans-serif;
}
