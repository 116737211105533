.banner {
    width: 100vw;
    height: 60vh;
    object-fit: contain;
}

.products {
    justify-content: center;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.product-container {
    font-family: sans-serif;
    width: 288px;
    height: 448px;
    border: 2px rgba(219, 219, 219, 0.563) solid;
    border-radius: 9px;
}

.product-image-container {
    margin-top: 8px;
    width: 288px;
    height: 300px;
    /* object-fit: cover; */
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-details {
    /* border: 1px orange solid; */
    padding: 14px;
}

.product-name-link {
    text-decoration: none;
    color: black;
    font-size: 15px;
}

.product-name-link:hover {
    color: rgb(14, 63, 169);
    text-decoration: underline;
}

.product-price {
    margin: 10px 0px;
    color: rgb(188, 40, 72);
    font-size: 18px;
    font-weight: bold;
}

.shipping {
    font-weight: lighter;
    font-size: 12px;
}
