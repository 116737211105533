.Footer {
    background-color: rgb(28, 73, 194);
    height: 70px;
    width: 100%;
    /* position: fixed; */
    /* bottom: 0;
    left: 0; */
    display: flex;
    /* flex-direction: row; */
    /* justify-content: center; */
    align-items: center;
    margin-top: 40px;
    justify-content: space-between;
    font-family: sans-serif;
    color: white;
    z-index: 1000;
}

.crunchy-footer {
    margin-left: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: monospace;
    font-weight: bolder;
    font-size: 40px;
}

.footer-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    margin-right: 30px;
}

.fa-github {
    color: white;
    transition: all .28s;
    font-size: 40px;
}

.fa-github:hover {
    color: rgb(155, 190, 255);
}

.fa-linkedin {
    color: white;
    transition: all .28s;
    font-size: 40px;
}

.fa-linkedin:hover {
    color: rgb(155, 190, 255);
}
