.pet-cover-image-container {
    margin-top: 26px;
    display: flex;
    justify-content: center;
}

.pet-cover-image {
    /* margin: auto; */
    border-radius: 22px;
    width: 95vw;
    height: 32vh;
    object-fit: cover;
}

.pet-profile-container {
    font-family: sans-serif;
}

.edit-pet-form {
    font-family: sans-serif;
    max-width: 65vw;
    margin: 45px auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.edit {
    font-weight: bolder;
    font-size: 22px;
}

.cancelForm {
    border-radius: 20px;
    border: rgba(232, 232, 232, 0.714) 2px solid;
    background: transparent;
    font-size: 14px;
    font-weight: bolder;
    padding: 5.5px 14px 5.5px 14px;
    color: rgb(93, 93, 93);
    cursor: pointer;
}

.cancelForm:hover {
    border: transparent 2px solid;
    background-color: rgba(232, 232, 232, 0.714);
}

.icon-image-edit-button {
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.profile-icon-container {
    display: flex;
    position: relative;
    bottom: 60px;
    left: 56px;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    width: 112px;
    height: 112px;
    border-radius: 80px;
    background-color: rgb(229, 234, 240);
}

.edit-button {
    position: relative;
    bottom: 46px;
    right: 41px;
    padding: 8px 12px;
    background-color: white;
    border-radius: 25px;
    border: transparent;
    border: 1px grey solid;
    width: 56px;
    text-decoration: none;
    color: rgb(0, 41, 87)
}

/* .fa-pen {
} */

.icon-label-image {
    margin-top: 18px;
}

.edit-label {
    color: rgb(73, 73, 73);
    font-size: 11px;
    margin-top: 10px;
}

.edit-input {
    font-size: 15px;
    padding: 16px;
    border-radius: 8px;
    border: 1px rgba(89, 89, 89, 0.755) solid;
    width: 95%;
    min-width: 400px;
    margin: auto;
}

.edit-input-padding {
    padding-top: 10px;
}

.pet-left-and-right-container {
    display: flex
}

.pet-name-details-menu-container {
    width: 304px;
    padding-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pet-name {
    font-family: sans-serif;
    font-size: 26px;
    font-weight: bolder;
    color: rgb(0, 41, 87);
    margin-bottom: 5px;
}

.pet-details-menu-container {
    font-size: 13px;
    font-weight: lighter;
    /* width: 304px;
    padding-left: 32px; */
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

.pet-details-container {
    display: flex;
    flex-direction: row;
}

.detail {
    font-family: sans-serif;
    color: rgb(0, 41, 87);
    width: fit-content;
    margin: auto;
}

.detail-label {
    font-family: sans-serif;
    color: rgb(123, 123, 123);
    width: fit-content;
    margin: auto;
}

.pet-menu {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}

.pet-feature {
    display: flex;
    align-items: center;
    width: 270px;
    height: 46px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    /* background-color: rgb(229, 234, 240); */
    color: rgb(0, 41, 87);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    transition: all .36s;
}

.pet-feature:hover {
    background-color: rgb(185, 200, 219);
}

.pet-feature-details {
    display: flex;
    align-items: center;
    width: 270px;
    height: 46px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    background-color: rgb(229, 234, 240);
    color: rgb(0, 41, 87);
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    transition: all .36s;
}

.pet-feature-details:hover {
    background-color: rgb(185, 200, 219);
}

.icon-and-text {
    margin-left: 10px;
    display: flex;
}

.feature-text {
    margin-left: 18px;
}

.fa-clipboard-list {
    font-size: 22px;
}

.feature-icon-container {
    /* position: relative; */
    margin: auto;
    display: flex;
    justify-content: center;
    width: 20px;
}

.line-H {
    /* padding-top: 20px; */
    border-bottom: 1px solid rgba(75, 75, 75, 0.645);
    width: 269px;
    margin-top: 20px;
}

.line-H-detail-page {
    border-bottom: 2px solid rgba(223, 223, 223, 0.645);
    width: 62vw;
    /* margin-bottom: 24px; */
}

.line-V {
    /* padding-top: 20px; */
    border-right: 1px solid rgba(75, 75, 75, 0.645);
    width: 1px;
    height: 30px;
    margin: 0px 20px 0px 20px;
}

.pet-icon-container-edit {
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 80px;
    background-color: rgb(229, 234, 240);
    cursor: pointer;
    margin: auto;
    transition: all .23s;
}

.pet-icon-container-edit:hover {
    /* transition-delay: .02s; */
    background-color: rgb(192, 206, 223);
}

.pet-icons-container-edit {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 560px;
    margin: auto;
    border: 2px rgba(196, 196, 196, 0.67) solid;
    border-radius: 8px;
    background-color: rgb(238, 238, 238);
}

.gender-input {
    font-size: 15px;
    padding: 16px;
    border-radius: 8px;
    border: 1px rgba(89, 89, 89, 0.755) solid;
    width: 95%;
    min-width: 400px;
    margin: auto;
}

.gender-input:focus {
    outline: none;
    border-bottom: 0px transparent solid;
    border-top: 2px rgb(28, 73, 194) solid;
    border-left: 2px rgb(28, 73, 194) solid;
    border-right: 2px rgb(28, 73, 194) solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.gender-list {
    border: 2px rgb(28, 73, 194) solid;
    border-top: transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 70%;
    /* height: 120px; */
    margin: auto;
    width: 61.75%;
    min-width: 400px;
    padding: 0px 16px 4px 16px;
    overflow: scroll;
    background-color: white;
    position: absolute;
}

.edit-breed-input {
    font-size: 15px;
    padding: 16px;
    border-radius: 8px;
    border: 1px rgba(89, 89, 89, 0.755) solid;
    width: 95%;
    min-width: 400px;
    margin: auto;
}

.edit-breed-input:focus {
    outline: none;
    border-bottom: 0px transparent solid;
    border-top: 2px rgb(28, 73, 194) solid;
    border-left: 2px rgb(28, 73, 194) solid;
    border-right: 2px rgb(28, 73, 194) solid;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.edit-breed-list {
    position: absolute;
    border: 2px rgb(28, 73, 194) solid;
    border-top: transparent;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 61.75%;
    height: 120px;
    margin: auto;
    /* max-width: 400px; */
    min-width: 400px;
    padding: 0px 16px 16px 16px;
    overflow: scroll;
    background-color: white;
}

.submit-edit {
    border: none;
    background-color: rgb(28, 73, 194);
    cursor: pointer;
    width: 36%;
    padding: 6px;
    border-radius: 45px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    /* max-width: 400px;
    min-width: 400px; */
    margin-top: 14px;
}

.submit-edit:hover {
    background-color: rgb(35, 83, 213);
}

.delete-profile {
    border: 2px rgba(193, 62, 62, 0.297) solid;
    background-color: transparent;
    cursor: pointer;
    width: 36%;
    padding: 4px;
    border-radius: 45px;
    color: rgb(193, 62, 62);
    font-weight: bold;
    font-size: 15px;
    /* max-width: 400px;
    min-width: 400px; */
    margin-top: 14px;
}

.delete-profile:hover {
    border: transparent 2px solid;
    background-color: rgba(193, 62, 62, 0.297);
}

.all-pet-details-container {
    position: relative;
    bottom: 30px;
}

.detail-page-title {
    /* margin-top: 20px; */
}

.detail-page-detail-label-container {
    margin-top: 30px;
}

.detail-page-label {
    font-weight: lighter;
    font-size: 12px;
    color: rgb(102, 102, 102);
    margin: 24px 0px 20px 0px;
}

.detail-page-detail {
    font-weight: lighter;
    font-size: 14px;
    color: rgb(77, 77, 77);
}

.edit-submit-delete-buttons {
    margin-top: 40px;
}
