.cart-background {
    background-color: rgb(238, 238, 238);
    font-family: sans-serif;
    border: 1px transparent solid;
    min-height: 76vh;
}

.cart-container {
    display: flex;
}

.cart-product-container {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 65vw;
    max-height: 800px;
    margin: 20px;
    border: 1px rgb(238, 238, 238) solid;
    padding-top: 22px;
}

.cart-product-list {
    overflow: scroll;
    width: 100%;
    height: 95%;
}

.place-order-container {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 28vw;
    height: 172px;
    margin-top: 20px;
    border: 1px rgb(238, 238, 238) solid;
    padding-top: 22px;
}

.cart-product-header {
    font-weight: bold;
    font-size: 20px;
    margin: 0px 0px 15px 34px;
}

.cart-product {
    border-top: 1px solid rgb(238, 238, 238);
    padding: 18px;
    display: flex;
}

.cart-product-image-container {
    width: 120px;
    height: 250px;
}

.cart-product-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cart-product-right-side {
    padding-left: 36px;
    max-width: 450px;
}

.cart-product-name-link {
    text-decoration: none;
    color: black;
    font-size: 15px;
}

.quantity-container {
    border: 1px rgba(194, 194, 194, 0.57) solid;
    width: fit-content;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    position: relative;
    top: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}

.quantityMinusSymbol {
    font-weight: lighter;
    font-size: 22px;
    /* margin: 0px 8px 4px 0px; */
    cursor: pointer;
}

.quantity {
    /* font-weight: bold; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    /* border: 1px solid black; */

    width: 30px;
    height: 30px;
    border-radius: 8px;
}

.quantityPlusSymbol {
    font-weight: lighter;
    font-size: 20px;
    /* margin: 0px 0px 3px 8px; */
    cursor: pointer;
}

.subtotal {
    font-weight: bold;
    font-size: 20px;
    margin: auto;
    width: 210px;
}

.total-items {
    font-size: 14px;
    font-weight: lighter;
    margin-top: 5px;
    margin-left: 36px;
    width: 50px;
}

.checkout-shipping {
    font-size: 12px;
    font-weight: lighter;
    margin: 20px auto 20px auto;
    width: 196px;
}

.fa-truck-arrow-right {
    font-size: 16px;
    margin-left: 5px;
}

.place-order-button {
    background-color: rgb(239, 108, 0);
    color: white;
    font-size: 14px;
    font-weight: bold;
    width: 220px;
    padding: 14px 0px 14px 0px;
    margin: auto;
    text-align: center;
    border-radius: 25px;
    cursor: pointer;
}

.place-order-button:hover {
    background-color: rgb(255, 129, 26);
}

.empty-cart-container {
    /* border: 1px orange solid; */
    font-family: sans-serif;
    background-color: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dog-in-box-image-container {
    width: 400px;
    height: 411px;
}

.dog-in-box-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.coming-soon-image-container {
    width: 500px;
    height: fit-content;
    margin-right: 20px;
}

.coming-soon-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: contain;
}

.the-404-image-container {
    width: 500px;
    height: fit-content;
    margin-right: 20px;
}

.the-404-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: contain;
}

.oops {
    font-size: 40px;
    font-weight: bolder;
    margin-bottom: 20px;
}

.continue-shopping-div {
    margin-top: 20px;
}

.continue-shopping-link {
    color: rgb(14, 112, 190);
}

.continue-shopping-link:hover {
    color: rgb(15, 82, 136);
}
