.NavBar {
    background-color: rgb(28, 73, 194);
    display: flex;
    justify-content: space-between;
}

.crunchy {
    margin-left: 10px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-family: monospace;
    font-weight: bolder;
    font-size: 50px;
}

.account-cart {
    display: flex;
    justify-content: end;
    column-gap: 10px;
    /* border: rgb(128, 0, 26) 2px solid; */
    width: 28vw;
}

.dropdown-main {
    font-family: sans-serif;
    /* display: flex; */
    /* flex-direction: column; */
    /* border: 1px orange solid; */
}

.account-dropdown-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgb(255, 255, 255);
    height: 60px;
    /* border:orange 1px solid; */
}

.account-dropdown-button:hover {
    background-color: rgb(21, 63, 178);
}

.account-dropdown-button-children {
    /* border: 1px orange solid; */
    padding: 5px 16px 5px 16px;
    display: flex;
}

.hi-user {
    color: rgba(255, 255, 255, 0.515);
}

.account {
    font-size: 20px;
}

.dropdown-arrow {
    margin: auto auto auto 10px;
    font-size: 13px;
    /* transform: rotate(90deg); */
    /* border: 3px green solid; */
}

.cart-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: sans-serif;
    color: rgb(255, 255, 255);
    /* border: 1px red solid; */
    margin-right: 20px;
}

.cart-button:hover {
    background-color: rgb(21, 63, 178);
}

.fa-cart-shopping {
    height: 18px;
    font-size: 30px;
}

.user-dropdown-container {
    /* top: 68px; */
    right: 100px;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 230px;
    max-height: 88vh;
    border: 1px rgb(210, 210, 210) solid;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 1px 3px 3px rgba(83, 83, 83, 0.78);
    overflow: scroll;
    z-index: 1000;
}

.user-dropdown-button {
    border-top: 1px solid rgb(210, 210, 210);
    padding: 20px 0px 20px 10px;
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: lighter;
    cursor: pointer;
    background-color: white;
    /* overflow: scroll; */
}

.user-dropdown-button:hover {
    text-decoration: underline;
}

.not-user {
    border-top: 1px solid rgb(210, 210, 210);
    padding: 20px 0px 20px 10px;
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: lighter;
    /* cursor: pointer; */
    background-color: white;
    /* overflow: scroll; */
}

.login-signup-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
    border-top: 1px solid rgb(210, 210, 210);
    padding: 20px 10px 20px 10px;
    text-decoration: none;
    /* color: black; */
    font-size: 15px;
    font-weight: lighter;
    /* cursor: pointer; */
    /* overflow: scroll; */
}

.login-button {
    font-weight: bold;
    margin: auto;
    padding: 14px 60px 14px 60px;
    border-radius: 4px;
    background-color: rgb(239, 108, 0);
    color: white;
    text-decoration: none;
}

.login-button:hover {
    background-color: rgb(199, 90, 2);
    text-decoration: underline;
}

.start-here {
    /* padding-top: 10px; */
    margin: 20px auto auto auto;
    font-size: 14px;
}

.signup-button {
    color: rgb(14, 112, 190);
    text-decoration: none;
}

.signup-button:hover {
    text-decoration: underline;
}

.sign-out {
    color: rgb(28, 73, 194);
    cursor: pointer;
}

.sign-out:hover {
    text-decoration: underline;
}
